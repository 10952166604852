import React from "react"

const E404 = () => {

  return (
      <h1>Página no encontrada</h1>
  )
}

export default E404
